import { css } from "@emotion/core";

const globalStyling = theme => css`
  * {
    box-sizing: border-box;
  }

  :root {
    background-color: ${theme.palette.background};
    font-size: ${theme.typography.sizes.regular}rem;
    ${theme.breakpoints.down("xs")} {
      font-size: ${theme.typography.sizes.small}rem;
    }
    height: 100%;
  }

  body {
    color: ${theme.palette.white};
    font-family: ${theme.typography.body}, sans-serif;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.typography.title}, sans-serif;
  }

  h2 {
    font-size: ${theme.typography.sizes.biggest}rem;
  }

  p {
    line-height: 2rem;
  }

  em {
    color: ${theme.palette.accent};
    font-style: normal;
  }

  ul {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    p + & {
      margin-top: -0.5rem;
    }
  }

  li {
    line-height: ${theme.typography.lineHeights.bigger};
    margin-top: 0.5rem;
  }

  a {
    color: ${theme.palette.white};
    text-decoration: none;

    &:not(.no-style) {
      position: relative;
      display: inline-block;
      font-weight: bold;
      line-height: 2rem;
      transition: ${theme.transition.default("color")};

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0.2rem;
        transform: translateY(-0.4rem);
        background-color: ${theme.palette.accent};
        opacity: ${theme.opacity.faded};
        transition: ${theme.transition.long("opacity")};
      }

      &:hover,
      &:focus,
      &:active {
        color: ${theme.palette.accent};

        &::before {
          opacity: 1;
        }
      }
    }

    &.no-style {
      color: inherit;
    }

    &[rel="noreferrer"] {
      &::after {
        content: "";
        display: inline-block;
        background-image: url(/images/externallink.svg);
        background-size: contain;
        margin-left: 0.4rem;
        height: 1rem;
        width: 1rem;
        transform: translateY(0.2rem);
      }

      &::before {
        width: calc(100% - 1rem - 0.4rem);
      }
    }
  }

  button {
    background-color: ${theme.palette.accent};
    border: none;
    color: ${theme.palette.background};
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: ${theme.transition.default("opacity")};
      box-sizing: border-box;
      opacity: 0;
      box-shadow: 0 0 25px 2px ${theme.palette.accent};
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      &::after {
        opacity: ${theme.opacity.faded};
      }
    }

    &:disabled {
      cursor: auto;
    }
  }

  button,
  textarea,
  input {
    &:disabled {
      opacity: ${theme.opacity.faded};
    }
  }

  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }

  html[data-body-class="no-js"] .js-only {
    display: none;
  }

  [data-reveal],
  [data-reveal-on-scroll],
  [data-reveal-with-delay] {
    //This class has to be set on animated elements with fade-in
    //it hides the element before GSAP is loaded, only if JS is activated
    html[data-body-class="js"][data-homepage] & {
      opacity: 0;
    }
  }
`;

export default globalStyling;
