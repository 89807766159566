import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import MenuItem from "./components/MenuItem";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { gsap } from "gsap";

const Menu = () => {
  const [scroll, setScroll] = useState(0);
  const [sections, setSections] = useState([]);
  const [open, setOpen] = useState(false);
  const [closed, setClosed] = useState(true);
  const [activeSection, setActiveSection] = useState(null);
  const [hovered, setHovered] = useState(false);

  let openTimeline = useRef(null);
  const barRef = useRef(null);

  const theme = useTheme();

  const hasSections = sections.length > 0;

  const toggleMenu = () => {
    setOpen(!open);
    if (!open) {
      setClosed(false);
    }
  };

  useLayoutEffect(() => {
    let ticking = false;

    const updatePosition = () => {
      ticking = false;

      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = scrollY / scrollHeight;

      const sectionsInfos = (() => {
        const sectionsInfos = [];
        const h2elements = [...document.querySelectorAll("[data-section]")];
        h2elements.forEach(element => {
          const elementPosition =
            (element.getBoundingClientRect().top + scrollY) / scrollHeight;
          sectionsInfos.push({
            id: element.id,
            label: element.dataset.section,
            position: elementPosition > 1 ? 1 : elementPosition,
          });
        });
        if (sectionsInfos.length > 0)
          sectionsInfos.unshift({
            id: "about",
            label: "À propos",
            position: 0,
          });
        return sectionsInfos;
      })();

      setScroll(scrollPosition);
      setSections(sectionsInfos);
    };

    const scrollHandler = () => {
      if (ticking) return;
      requestAnimationFrame(updatePosition);
      ticking = true;
    };

    updatePosition();
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  useEffect(() => {
    if (!hasSections) return;

    if (open) {
      if (openTimeline.current && openTimeline.current.reversed()) {
        // If reverse animation is running, do not reset timeline
        openTimeline.current.play();
      } else {
        // Has to be reset at every opening to include the current element style
        openTimeline.current = gsap.timeline({
          onReverseComplete: () => {
            openTimeline.current = null;
            document.querySelector("nav").classList.add("sr-only");
            setClosed(true);
          },
        });

        document.querySelector("nav").classList.remove("sr-only");

        openTimeline.current
          .set("[data-menu-overlay], [data-menu-stroke]", {
            display: "block",
          })
          .to("[data-menu-overlay]", {
            opacity: 0.9,
            duration: 0.3,
            ease: "power2.out",
          })
          .to("[data-scroll-bar]", { scaleX: 2, opacity: 1 }, 0)
          .to(
            "[data-menu-cursor]",
            {
              width: "12px",
              height: "12px",
              opacity: 1,
              stagger: 0.1,
              duration: 0.3,
            },
            0
          )
          .to(
            "[data-menu-stroke]",
            {
              width: "3rem",
              stagger: 0.1,
              ease: "back",
            },
            0.1
          )
          .to(
            "[data-menu-label]",
            {
              opacity: 1,
              x: "-=3rem",
              stagger: 0.1,
              ease: "back",
            },
            0.1
          );
      }
    } else if (openTimeline.current) {
      openTimeline.current.reverse();
    }
  }, [open, hasSections]);

  return (
    <div className="js-only">
      <Overlay data-menu-overlay onClick={toggleMenu} />
      <MenuContainer
        id="menu"
        open={open}
        onClick={toggleMenu}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Switch
          href=""
          className="no-style"
          onClick={event => {
            event.preventDefault();
            toggleMenu();
          }}
          aria-live="polite"
          highlighted={hovered && closed}
        >
          {open ? "Fermer" : "Menu"}
        </Switch>
        <BarContainer ref={barRef}>
          <ScrollBar data-scroll-bar highlighted={hovered && closed} />
          <ProgressBar
            style={{ height: `${scroll * 100}%` }}
            data-scroll-bar
            highlighted={hovered && closed}
          />
          {sections.map((section, index) => (
            <MenuItem
              key={index}
              position={section.position}
              label={section.label}
              passed={scroll >= section.position}
              active={activeSection === index}
              highlighted={hovered && closed}
            />
          ))}
          <nav
            role="navigation"
            aria-label="menu principal"
            className="sr-only"
          >
            <ul>
              {sections.map((section, sectionIndex) => (
                <Li key={sectionIndex}>
                  <A
                    href={`#${section.id}`}
                    onClick={toggleMenu}
                    onFocus={() => {
                      setActiveSection(sectionIndex);
                    }}
                    onBlur={() => setActiveSection(null)}
                    tabIndex={open ? 0 : -1}
                    className="no-style"
                  >
                    <Label
                      style={{
                        top: `${section.position * 100}%`,
                      }}
                      data-menu-label
                      onMouseEnter={() => {
                        setActiveSection(sectionIndex);
                      }}
                      onMouseLeave={() => {
                        setActiveSection(null);
                      }}
                    >
                      {[...section.label].map(
                        (letter, letterIndex, letters) => (
                          <Letter
                            key={letterIndex}
                            style={{
                              color:
                                sectionIndex === activeSection &&
                                theme.palette.accent,
                              transform:
                                sectionIndex === activeSection &&
                                `translateX(calc(${
                                  -(letters.length - 1 - letterIndex) * 2
                                }px - 1.5rem))`,
                            }}
                            aria-hidden
                          >
                            {letter}
                          </Letter>
                        )
                      )}
                    </Label>
                    <span className="sr-only">{section.label}</span>
                  </A>
                </Li>
              ))}
            </ul>
          </nav>
        </BarContainer>
      </MenuContainer>
    </div>
  );
};

Menu.propTypes = {};

const Switch = styled.a`
  position: absolute;
  left: ${({ theme }) => theme.container.defaultMargin * 0.5}px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    left: 0;
  }
  transform-origin: left;
  transform: rotate(90deg) translateX(-30px);
  font-size: ${({ theme }) => theme.typography.sizes.regular}rem;
  text-transform: uppercase;
  transition: opacity 500ms;
  color: ${({ theme, highlighted }) =>
    highlighted ? theme.palette.accent : theme.palette.white} !important;
`;

const MenuContainer = styled.div`
  position: fixed;
  top: 5rem;
  right: 0;
  bottom: 5rem;
  width: ${({ theme }) => theme.container.defaultMargin}px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: ${({ theme }) => theme.container.smMargin}px;
  }
  z-index: 2000;
  cursor: pointer;
`;

const BarContainer = styled.div`
  position: absolute;
  width: 1px;
  top: 5rem;
  right: ${({ theme }) => theme.container.defaultMargin * 0.5}px;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    right: ${({ theme }) => theme.container.smMargin}px;
  }
  bottom: 3rem;
`;

const ScrollBar = styled.div`
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.palette.accent : theme.palette.white};
  opacity: ${({ theme }) => theme.opacity.light};
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.palette.accent : theme.palette.white};
  opacity: ${({ theme }) => theme.opacity.faded};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.background};
  opacity: 0;
  backdrop-filter: blur(4px);
  z-index: 900;
  display: none;
`;

const Li = styled.li`
  display: inline;
`;

const A = styled.a`
  color: ${({ theme }) => theme.palette.white};
`;

const Label = styled.span`
  position: absolute;
  font-family: ${({ theme }) => theme.typography.title};
  font-size: ${({ theme }) => theme.typography.sizes.biggest}rem;
  top: 0;
  right: 4rem;
  text-align: right;
  transform: translateY(-55%);
  opacity: 0;
  white-space: nowrap;
`;

const Letter = styled.span`
  white-space: pre;
  display: inline-block;
  transition: color 300ms ease-in-out, transform 300ms ease-in-out;
`;

export default Menu;
