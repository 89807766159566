import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Container = ({ children }) => (
  <OuterBox>
    <InnerBox>{children}</InnerBox>
  </OuterBox>
);

Container.propTypes = {
  children: PropTypes.node,
};

const OuterBox = styled.div`
  padding: 0 ${props => props.theme.container.defaultMargin}px;
  display: flex;
  justify-content: center;
  ${props => props.theme.breakpoints.down("sm")} {
    padding: 0 ${props => props.theme.container.smMargin * 2}px 0
      ${props => props.theme.container.smMargin}px;
  }
`;

const InnerBox = styled.div`
  box-sizing: content-box;
  width: 100%;
  max-width: ${props => props.theme.container.maxWidth}px;
`;

export default Container;
