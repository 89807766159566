import React from "react";
import PropTypes from "prop-types";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import emotionNormalize from "emotion-normalize";
import { ThemeProvider } from "emotion-theming";
import { theme, globalStyling } from "../../styles";
import SEO from "./seo";
import Container from "./Container";
import { useStaticQuery, graphql, Link } from "gatsby";
import Menu from "../Menu/Menu";
import "@fontsource/lato";
import "@fontsource/lato/700.css";
import "@fontsource/lexend-deca";

const Layout = ({ subsection, children }) => {
  const {
    dataYaml: { about, socials },
  } = useStaticQuery(layoutQuery);

  const globalCss = theme => css`
    ${emotionNormalize}
    ${globalStyling(theme)}
  `;

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalCss} />
      <SEO />
      <Header role="banner" data-reveal-with-delay>
        <Container>
          <Link to="/" title="Retour à l'accueil" className="no-style">
            <Title>{about.title}</Title>
          </Link>
          {subsection && (
            <>
              <Separator aria-hidden />
              <Subsection>{subsection}</Subsection>
            </>
          )}
        </Container>
        {!subsection && <Menu data-reveal-with-delay />}
      </Header>
      <main role="main">
        <Content>{children}</Content>
      </main>
      <Footer data-reveal-with-delay>
        <Container>
          <SocialNav>
            {socials.map((social, index) => (
              <Social
                key={index}
                href={`//${social.url}`}
                image={social.image}
                className="no-style"
                title={social.alt}
                target="_blank"
              />
            ))}
          </SocialNav>
        </Container>
      </Footer>
    </ThemeProvider>
  );
};

const layoutQuery = graphql`
  query LayoutQuery {
    dataYaml {
      about {
        title
      }
      socials {
        alt
        image
        url
      }
    }
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  background-color: ${props => props.theme.palette.background};
  padding-top: ${props => props.theme.spacing.header}rem;
  padding-bottom: 1rem;
  left: 0;
  width: 100%;
  z-index: 999;
`;

const Title = styled.span`
  opacity: ${props => props.theme.opacity.faded};
  font-size: ${props => props.theme.typography.sizes.bigger}rem;
  font-family: ${props => props.theme.typography.title};
`;

const Separator = styled.div`
  display: inline-block;
  margin: 0 1.5rem 0.4rem 1.5rem;
  height: 1px;
  background-color: ${props => props.theme.palette.white};
  width: 2rem;
`;

const Subsection = styled.span`
  font-size: ${props => props.theme.typography.sizes.bigger}rem;
  font-family: ${props => props.theme.typography.title};
`;

const Content = styled.div`
  padding-top: ${props =>
    props.theme.spacing.header + props.theme.spacing.topContentOffset}rem;
  padding-bottom: ${props =>
    props.theme.spacing.footer + props.theme.spacing.bottomContentOffset}rem;
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  background-color: ${props => props.theme.palette.background};
  padding-bottom: ${props => props.theme.spacing.footer}rem;
  padding-top: 1rem;
  left: 0;
  width: 100%;
  z-index: 999;
`;

const SocialNav = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Social = styled.a`
  display: inline-block;
  height: 2rem;
  width: 2rem;
  margin-left: 0.75rem;
  background-image: url(${props => props.image});
  background-size: contain;
  opacity: ${props => props.theme.opacity.faded};
  transition: ${props => props.theme.transition.default("opacity")};

  &:hover {
    opacity: 1;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
