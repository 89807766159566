const theme = {
  palette: {
    background: "#090E1F",
    accent: "#5FDAF0",
    white: "#FFFFFF",
    success: "#14FF00",
    error: "#FF0000",
  },
  typography: {
    title: "Lexend Deca",
    body: "Lato",
    sizes: {
      small: 0.9,
      regular: 1,
      important: 1.25,
      bigger: 1.5,
      biggest: 1.8,
      title: 3.5,
    },
    lineHeights: {
      bigger: "1.75",
      biggest: "2.25",
    },
  },
  opacity: {
    overlay: 0.8,
    faded: 0.6,
    light: 0.3,
    xLight: 0.18,
    bare: 0.05,
  },
  breakpoints: {
    down: bp => `@media screen and (max-width: ${theme.breakpoints[bp].upper})`,
    up: bp => `@media screen and (min-width: ${theme.breakpoints[bp].lower})`,
    xs: {
      upper: "575.98px",
      lower: "0px",
    },
    sm: { upper: "767.98px", lower: "576px" },
    md: { upper: "991.98px", lower: "768px" },
    lg: { upper: "1199.98px", lower: "992px" },
    xl: { lower: "1200px", upper: "1598px" },
  },
  spacing: {
    header: 2,
    footer: 1.5,
    topContentOffset: 4,
    bottomContentOffset: 2,
  },
  transition: {
    short: property => `${property} 150ms ease-out`,
    default: property => `${property} 300ms ease-out`,
    long: property => `${property} 1000ms ease-out`,
  },
  decoration: {
    circleSize: 180,
    targetSize: 2,
    smallTargetSize: 28,
  },
  container: {
    maxWidth: 1280,
    defaultMargin: 120,
    smMargin: 20,
  },
};

export default theme;
