import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";

const MenuItem = ({
  position,
  passed = false,
  active = false,
  highlighted = false,
}) => {
  const theme = useTheme();

  return (
    <>
      <Cursor
        aria-hidden
        style={{
          top: `${position * 100}%`,
          opacity: passed ? 1 : 0.5,
          width: passed ? "6px" : "4px",
          height: passed ? "6px" : "4px",
        }}
        data-menu-cursor
        highlighted={highlighted || active}
        active={active}
      />
      <Stroke
        style={{
          top: `${position * 100}%`,
          backgroundColor: active && theme.palette.accent,
          transform: active && `scaleX(1.5)`,
        }}
        data-menu-stroke
        aria-hidden
      />
    </>
  );
};

MenuItem.propTypes = {
  position: PropTypes.number.isRequired,
  passed: PropTypes.bool,
  active: PropTypes.bool,
  highlighted: PropTypes.bool,
};

const Cursor = styled.div`
  position: absolute;
  left: 0;
  width: 3px;
  height: 3px;
  transform: translate(-50%, -50%)
    ${({ highlighted, active }) => highlighted && active && "scale(2)"};
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.palette.accent : theme.palette.white};
  border-radius: 50%;
  transition: transform 300ms ease;
`;

const Stroke = styled.div`
  position: absolute;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.white};
  width: 0;
  right: 2rem;
  display: none;
  transform-origin: right;
  transition: background-color 400ms ease-in-out, transform 300ms ease-in-out;
`;

export default MenuItem;
